import React, { useState, useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Header from './Header';
import Footer from './Footer';
import '../styles/layout.css';
import { widths } from '../utils/constants';

const Layout = ({ children, year }) => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Header />
      <div className={css(styles.body)}>
        <main>{children}</main>
      </div>
      <Footer year={year} />
    </>
  );
};

export default Layout;

const styles = StyleSheet.create({
  body: {
    margin: '0 auto',
    flex: 1,
    padding: 40,
    maxWidth: 1260,
    width: '100%',
    [`@media ${widths.mobile}`]: {
      padding: 20,
    },
  },
});
