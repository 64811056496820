export const colors = {
  black: '#000',
  darkGray: '#5B5B5B',
  textGray: '#717073',
  mediumGray: '#848484',
  lightGray: '#eee',
  lightestGray: '#fcfcfc',
  white: '#fff',
  blue: '#3473b1',
};

export const widths = {
  mobileSmallest: '(max-width: 350px)',
  mobileSmall: '(max-width: 400px)',
  mobileLarge: '(max-width: 767px) and (min-width: 401px)',
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 767px) and (max-width: 1024px)',
  device: '(max-width: 1024px)',
  desktop: '(min-width: 1025px)',
  desktopSmall: '(min-width: 1025px) and (max-width: 1199px)',
  desktopLarge: '(min-width: 1200px)',
  landscape: '(orientation: landscape)',
  portrait: '(orientation: portrait)',
};
