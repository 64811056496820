import React, { useState } from 'react';
import { Link } from 'gatsby';
import { StyleSheet, css } from 'aphrodite/no-important';
import { colors, widths } from '../utils/constants';

const NavItem = ({ data }) => {
  if (!data) {
    return null;
  }
  const { links, title } = data;

  if (links.length === 1) {
    const slug = links[0].slug;

    return (
      <Link to={`/${slug}`} className={css(styles.navItem)}>
        <div>{title}</div>
      </Link>
    );
  }

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      className={css(styles.navItem)}
      onClick={() => setShowDropdown(!showDropdown)}
    >
      <div>{title}</div>
      {links && showDropdown && (
        <div className={css(styles.dropdown)}>
          {links.map((item, index) => (
            <Link
              key={index}
              to={`/${item.slug}`}
              className={css(styles.dropdownItem)}
            >
              {item.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const MobileNav = ({ navItems, typeformUrl, showMobileNav }) => (
  <div>
    <div className={css(showMobileNav && styles.overlay)} />
    <div className={css(styles.nav, showMobileNav && styles.showing)}>
      <NavItem data={{ title: 'Home', links: [{ slug: '' }] }} />
      {navItems.map((n, index) => (
        <NavItem data={n} key={index} />
      ))}
      <a
        href={typeformUrl}
        className={`${css(styles.navItem)} typeform-share button typeform-btn`}
        data-mode="drawer_right"
        target="_blank"
      >
        <div>Contact Us</div>
      </a>
    </div>
  </div>
);

export default MobileNav;

const styles = StyleSheet.create({
  nav: {
    display: 'none',
    transition: 'all 0.1s ease-out',
    [`@media ${widths.device}`]: {
      display: 'block',
      position: 'fixed',
      right: '-80%',
      height: '100vh',
      backgroundColor: colors.lightestGray,
      color: colors.textGray,
      boxShadow: '0 3px 6px rgba(0, 0, 0, 0.161)',
      padding: 20,
      width: '80%',
      maxWidth: 400,
    },
  },
  showing: {
    [`@media ${widths.device}`]: {
      right: 0,
    },
  },
  navItem: {
    color: colors.blue,
    padding: '25px 0 15px',
    textDecoration: 'none',
    borderBottom: `1px solid ${colors.lightGray}`,
    fontSize: 14,
    display: 'block',
    textAlign: 'left',
    cursor: 'pointer',
    ':last-child': {
      borderBottom: 'none',
    },
  },
  dropdown: {
    padding: '10px 0 0 20px',
  },
  dropdownItem: {
    display: 'block',
    padding: '25px 0 15px',
    textDecoration: 'none',
    borderBottom: `1px solid ${colors.lightGray}`,
    ':last-child': {
      borderBottom: 'none',
    },
  },
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    transition: 'all 0.1s ease-out',
  },
});
