import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { StyleSheet, css } from 'aphrodite/no-important';
import { colors, widths } from '../utils/constants';

const NavItem = ({ data, secondaryColor }) => {
  if (!data) {
    return null;
  }
  const { links, title } = data;

  if (links.length === 1) {
    const slug = links[0].slug;
    const isCurrent = slug
      ? window.location.pathname.includes(slug)
      : window.location.pathname === '/';

    return (
      <Link
        to={`/${slug.replace(/\/$/, '')}/`}
        className={css(styles.navItem, isCurrent && styles.current)}
      >
        <div className={css(styles.navText)}>{title}</div>
      </Link>
    );
  }

  const [showDropdown, setShowDropdown] = useState(false);

  const isCurrent = links.find((l) =>
    window.location.pathname.includes(l.slug)
  );

  return (
    <div
      className={css(styles.navItem, isCurrent && styles.current)}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <div className={css(styles.navText)}>{title}</div>
      {links && showDropdown && (
        <div className={css(styles.dropdown)}>
          {links.map((item, index) => (
            <Link
              key={index}
              to={`/${item.slug.replace(/\/$/, '')}/`}
              className={css(styles.dropdownItem)}
            >
              {item.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const Nav = ({ navItems, logo, secondaryColor, typeformUrl }) => {
  useEffect(() => {
    (function () {
      let js, q;
      const d = document,
        gi = d.getElementById,
        ce = d.createElement,
        gt = d.getElementsByTagName,
        id = 'typef_orm',
        b = 'https://embed.typeform.com/';
      if (!gi.call(d, id)) {
        js = ce.call(d, 'script');
        js.id = id;
        js.src = `${b}embed.js`;
        q = gt.call(d, 'script')[0];
        q.parentNode.insertBefore(js, q);
      }
    })();
  }, []);

  return (
    <div className={css(styles.nav)}>
      <Link to="/" className={css(styles.logoLink)}>
        <img
          src={logo.file.url}
          alt={logo.title}
          className={css(styles.logo)}
        />
      </Link>
      {navItems.map((n, index) => (
        <NavItem data={n} key={index} secondaryColor={secondaryColor} />
      ))}
      <a
        href={typeformUrl}
        className={`${css(styles.button)} typeform-share button typeform-btn`}
        data-mode="drawer_right"
        target="_blank"
        style={{
          backgroundColor: secondaryColor,
        }}
      >
        Contact Us
      </a>
    </div>
  );
};

export default Nav;

const styles = StyleSheet.create({
  nav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 'auto',
    width: 1200,
    maxWidth: '100%',
    minHeight: 67,
    padding: '0 40px',
    [`@media ${widths.tablet}`]: {
      paddingLeft: 100,
    },
    [`@media ${widths.mobile}`]: {
      paddingLeft: 60,
      paddingRight: 20,
    },
  },
  navItem: {
    textDecoration: 'none',
    cursor: 'pointer',
    position: 'relative',
    fontWeight: 'bold',
    fontSize: 16,
    whiteSpace: 'nowrap',
    borderBottom: '7px solid transparent',
    color: colors.white,
    borderTop: '7px solid transparent',
    [`@media ${widths.device}`]: {
      display: 'none',
    },
  },
  navText: {
    margin: '24px 0',
  },
  dropdown: {
    position: 'absolute',
    background: colors.lightestGray,
    color: colors.textGray,
    fontWeight: 'normal',
    padding: '10px 20px',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    whiteSpace: 'nowrap',
    marginTop: 7,
    boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.161)',
    minWidth: 200,
    zIndex: 10,
  },
  dropdownItem: {
    padding: '25px 0 15px',
    textDecoration: 'none',
    borderBottom: `1px solid ${colors.lightGray}`,
    width: '100%',
    textAlign: 'left',
    ':last-child': {
      borderBottom: 'none',
    },
  },
  logoLink: {
    marginLeft: 0,
    padding: '10px 0',
  },
  logo: {
    width: 200,
    [`@media ${widths.device}`]: {
      margin: 0,
    },
    [`@media ${widths.mobile}`]: {
      width: 120,
    },
  },
  button: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 14,
    whiteSpace: 'nowrap',
    padding: '9px 30px',
    borderRadius: 30,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    color: colors.white,
    marginRight: 0,
    [`@media ${widths.mobileSmallest}`]: {
      padding: '7px 14px',
      fontSize: 11,
    },
  },
  current: {
    borderBottomColor: colors.white,
  },
});
