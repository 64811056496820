import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Link } from 'gatsby';
import Context from '../context/Context';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { colors, widths } from '../utils/constants';

const NavLink = ({ data, secondaryColor }) => {
  if (!data) {
    return null;
  }
  const { links, title } = data;

  const slug = links[0].slug;
  const isCurrent = slug
    ? window.location.pathname.includes(slug)
    : window.location.pathname === '/';

  return (
    <div>
      <Link
        to={`/${slug}`}
        className={css(styles.navItem, isCurrent && styles.current)}
      >
        <div className={css(styles.navText)}>{title}</div>
      </Link>
    </div>
  );
};

const Footer = ({ year }) => (
  <Context.Consumer>
    {({ companyName, logo, navItems, typeform, footerText, email, phone }) => (
      <footer className={css(styles.footer)}>
        <div className={css(styles.footerInner)}>
          <div className={css(styles.column, styles.columnLarge)}>
            <Link to="/">
              <img
                src={logo.file.url}
                alt={logo.title}
                className={css(styles.logo)}
              />
            </Link>
            <div className={css(styles.footerText)}>
              {documentToReactComponents(footerText.json)}
            </div>
          </div>
          <div className={css(styles.columnGroup)}>
            <div className={css(styles.column)}>
              <div className={css(styles.title)}>Site map</div>
              {navItems.map((n, index) => (
                <NavLink data={n} key={index} />
              ))}
              <a
                href={typeform}
                className={`${css(
                  styles.navItem
                )} typeform-share button typeform-btn`}
                data-mode="drawer_right"
                target="_blank"
              >
                <div className={css(styles.navText)}>Contact Us</div>
              </a>
            </div>
            <div className={css(styles.column)}>
              <div className={css(styles.title)}>Contact Us</div>
              <div className={css(styles.contactItem)}>
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 20 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css(styles.icon)}
                >
                  <path
                    d="M19.6211 4.95312C19.7734 4.83203 20 4.94531 20 5.13672V13.125C20 14.1602 19.1602 15 18.125 15H1.875C0.839844 15 0 14.1602 0 13.125V5.14062C0 4.94531 0.222656 4.83594 0.378906 4.95703C1.25391 5.63672 2.41406 6.5 6.39844 9.39453C7.22266 9.99609 8.61328 11.2617 10 11.2539C11.3945 11.2656 12.8125 9.97266 13.6055 9.39453C17.5898 6.5 18.7461 5.63281 19.6211 4.95312ZM10 10C10.9062 10.0156 12.2109 8.85938 12.8672 8.38281C18.0508 4.62109 18.4453 4.29297 19.6406 3.35547C19.8672 3.17969 20 2.90625 20 2.61719V1.875C20 0.839844 19.1602 0 18.125 0H1.875C0.839844 0 0 0.839844 0 1.875V2.61719C0 2.90625 0.132812 3.17578 0.359375 3.35547C1.55469 4.28906 1.94922 4.62109 7.13281 8.38281C7.78906 8.85938 9.09375 10.0156 10 10Z"
                    fill="#B1B1B1"
                  />
                </svg>
                <a href={`mailto:${email}`} className={css(styles.contactLink)}>
                  {email}
                </a>
              </div>
              <div className={css(styles.contactItem)}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={css(styles.icon)}
                >
                  <path
                    d="M0.726549 0.96134L4.78897 0.0238594C5.23036 -0.0777009 5.68348 0.152763 5.86316 0.566817L7.73812 4.94172C7.90218 5.32453 7.79281 5.77374 7.4686 6.03545L5.10146 7.97291C6.50768 10.9689 8.96466 13.4611 12.0232 14.8946L13.9607 12.5275C14.2263 12.2033 14.6716 12.0939 15.0544 12.258L19.4293 14.1329C19.8473 14.3165 20.0777 14.7696 19.9762 15.211L19.0387 19.2735C18.941 19.6953 18.566 20 18.1246 20C8.12093 20 0 11.8947 0 1.87538C0 1.43789 0.300777 1.05899 0.726549 0.96134Z"
                    fill="#B1B1B1"
                  />
                </svg>
                <a href={`tel:${phone}`} className={css(styles.contactLink)}>
                  {phone}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={css(styles.copyright)}>
          Copyright &copy; {year} {companyName}. All Rights Reserved.
        </div>
      </footer>
    )}
  </Context.Consumer>
);

export default Footer;

const styles = StyleSheet.create({
  footer: {
    minHeight: 100,
    color: colors.white,
    width: '100%',
    backgroundColor: colors.mediumGray,
  },
  footerInner: {
    padding: 40,
    width: 1200,
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    [`@media ${widths.device}`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  columnGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    [`@media ${widths.device}`]: {
      width: '100%',
    },
    [`@media ${widths.mobile}`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  columnLarge: {
    flex: 3,
  },
  column: {
    padding: 20,
    width: '100%',
  },
  logo: {
    width: 160,
    marginBottom: 20,
  },
  footerText: {
    paddingRight: 20,
  },
  title: {
    marginBottom: 20,
    paddingBottom: 10,
    borderBottom: `3px solid ${colors.lightGray}`,
    display: 'inline-block',
  },
  navItem: {
    marginBottom: 10,
    display: 'inline-block',
    textDecoration: 'none',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: colors.white,
    ':hover': {
      textDecoration: 'underline',
    },
  },
  contactItem: {
    marginBottom: 10,
    whiteSpace: 'nowrap',
  },
  icon: {
    marginRight: 10,
  },
  copyright: {
    backgroundColor: colors.darkGray,
    textAlign: 'center',
    padding: 30,
    borderTop: `30px solid ${colors.textGray}`,
  },
  contactLink: {
    textDecoration: 'none',
    color: colors.white,
    ':hover': {
      textDecoration: 'underline',
    },
  },
});
