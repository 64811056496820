import React, { useState } from 'react';
import Nav from './Nav';
import MobileNav from './MobileNav';
import Context from '../context/Context';
import { StyleSheet, css } from 'aphrodite/no-important';
import { colors, widths } from '../utils/constants';

const Hamburger = ({ showMobileNav, setShowMobileNav }) => (
  <div
    className={css(styles.iconWrapper)}
    onClick={() => setShowMobileNav(!showMobileNav)}
  >
    {showMobileNav ? (
      <svg
        aria-hidden="true"
        focusable="false"
        role="presentation"
        className={css(styles.icon)}
        viewBox="0 0 20 20"
      >
        <path
          fill={colors.white}
          d="M15.89 14.696l-4.734-4.734 4.717-4.717c.4-.4.37-1.085-.03-1.485s-1.085-.43-1.485-.03L9.641 8.447 4.97 3.776c-.4-.4-1.085-.37-1.485.03s-.43 1.085-.03 1.485l4.671 4.671-4.688 4.688c-.4.4-.37 1.085.03 1.485s1.085.43 1.485.03l4.688-4.687 4.734 4.734c.4.4 1.085.37 1.485-.03s.43-1.085.03-1.485z"
        />
      </svg>
    ) : (
      <svg
        aria-hidden="true"
        focusable="false"
        role="presentation"
        className={css(styles.icon)}
        viewBox="0 0 20 20"
      >
        <path
          fill={colors.white}
          d="M17.543 6.2H2.1a1.032 1.032 0 0 1-1.029-1.029c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029zm0 4.829H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029zm0 4.828H2.1a1.032 1.032 0 0 1-1.029-1.029c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
        />
      </svg>
    )}
  </div>
);

const Header = () => {
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <Context.Consumer>
      {({ navItems, primaryColor, secondaryColor, logo, typeform }) => (
        <header
          className={css(styles.header)}
          style={{ backgroundColor: primaryColor }}
        >
          <Hamburger
            showMobileNav={showMobileNav}
            setShowMobileNav={setShowMobileNav}
          />
          <Nav
            navItems={navItems}
            logo={logo}
            secondaryColor={secondaryColor}
            typeformUrl={typeform}
          />
          <MobileNav
            navItems={navItems}
            typeformUrl={typeform}
            showMobileNav={showMobileNav}
          />
        </header>
      )}
    </Context.Consumer>
  );
};

export default Header;

const styles = StyleSheet.create({
  header: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: colors.white,
    width: '100%',
    position: 'relative',
  },
  icon: {
    display: 'none',
    [`@media ${widths.device}`]: {
      display: 'block',
      left: 40,
      width: 30,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    [`@media ${widths.mobile}`]: {
      left: 20,
      width: 24,
    },
  },
});
